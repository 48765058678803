import React, { useEffect, useState, useContext } from "react";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Link } from "react-router-dom";
import { Button, Pagination, Stack } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { DarkModeContext } from "../../context/darkModeContext"; // Import dark mode context
import adminBackendHost from "../../Api";
import { AlertContext } from "../../context/alertContext";

const getTheme = (darkMode) => createTheme({
  palette: {
    mode: darkMode ? 'dark' : 'light',
    primary: {
      main: darkMode ? '#90caf9' : '#1976d2',
    },
    background: {
      paper: darkMode ? '#111' : '#fff',
      default: darkMode ? '#110e15' : '#fafafa',
    },
    text: {
      primary: darkMode ? '#fff' : '#000',
      secondary: darkMode ? '#aaa' : '#555',
    },
  },
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#7451f8",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    width: "400px",
    color: theme.palette.text.primary,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 1,
  },
}));

export default function SubCategoryTable() {
  const { darkMode } = useContext(DarkModeContext);
  const [subcategories, setSubCategories] = useState([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage] = useState(10);
  const dispatchAlert = useContext(AlertContext);

  useEffect(() => {
    fetchSubCategories();
  }, []);

  const fetchSubCategories = async () => {
    try {
      adminBackendHost.get("/subCategory/getAllSubCategories")
        .then(res => {
          console.log(res?.data)
          setSubCategories(res?.data?.subcategories)
        })
        .catch(err => {
          alert(err?.response?.data?.message)
          dispatchAlert({
            type: "SHOW_ALERT",
            payload: {
              type: "danger",
              message: err?.response?.data?.message,
            },
          });

        })
    } catch (error) {
      console.error("Error fetching subcategories:", error);
      dispatchAlert({
        type: "SHOW_ALERT",
        payload: {
          type: "danger",
          message: error?.message,
        },
      });
    }
  };

  const handleDelete = async (id) => {
    try {
      adminBackendHost.delete(`/subCategory/deleteSubCategory/${id}`)
        .then(res => {
          console.log(res?.data)
          setSubCategories((prevSubCategories) =>
            prevSubCategories.filter((subcategory) => subcategory._id !== id)
          );
        })
        .catch(err => alert(err?.response?.data?.message))
    } catch (error) {
      console.error("Error deleting subcategory:", error);
    }
  };

  const totalPages = Math.ceil(subcategories.length / rowsPerPage);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const indexOfLastItem = page * rowsPerPage;
  const indexOfFirstItem = indexOfLastItem - rowsPerPage;
  const currentItems = subcategories.slice(indexOfFirstItem, indexOfLastItem);

  const theme = getTheme(darkMode);

  return (
    <ThemeProvider theme={theme}>
      <div className="table-1">
        <div className="table-main">
          <div className="table-head">
            <h2 className="table-title">Sub-Category List</h2>
            <div className="add_button">
              <Link to="/subcategory/add">
                <Button variant="outlined">
                  <AddIcon style={{ fontSize: "20px", margin: "3px" }} />
                  add
                </Button>
              </Link>
            </div>
          </div>

          <div className="table-user">
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Sr.No</StyledTableCell>
                    <StyledTableCell>Category</StyledTableCell>
                    <StyledTableCell>Sub-Category</StyledTableCell>
                    <StyledTableCell>Edit</StyledTableCell>
                    <StyledTableCell>Delete</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {currentItems.map((subcategory, index) => (
                    <StyledTableRow key={subcategory._id}>
                      <StyledTableCell>
                        {index + 1 + indexOfFirstItem}
                      </StyledTableCell>
                      <StyledTableCell>{subcategory.name}</StyledTableCell>
                      <StyledTableCell>{subcategory.subcategory}</StyledTableCell>
                      <StyledTableCell>
                        <EditIcon />
                      </StyledTableCell>
                      <StyledTableCell>
                        <Link onClick={() => handleDelete(subcategory._id)}>
                          <DeleteIcon />
                        </Link>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <div className="pagination">
              <Stack spacing={2}>
                <Pagination
                  count={totalPages}
                  page={page}
                  onChange={handleChangePage}
                  variant="outlined"
                  color="primary"
                  boundaryCount={2}
                />
              </Stack>
            </div>
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
}
