

import React, { useState } from 'react'
import adminBackendHost from '../Api'

const AddCat = () => {
    const [name, setName] = useState()
    const [file, setFile] = useState()
    const handleSubmit = (e) => {
        e.preventDefault();
        let formdata = new FormData()
        formdata.append("file", file)
        formdata.append("name", name)
        adminBackendHost.post("/addCat", formdata)
            .then(res => console.log(res))
            .catch(err => console.log(err))
    }
    return (
        <div>
            <form onSubmit={handleSubmit}>
                <label htmlFor="">Add Cat Name : </label>
                <input type="text" name="" value={name} onChange={(e) => setName(e.target.value)} id="" />
                <input type="file" name="" onChange={(e) => setFile(e.target.files[0])} id="" />
                <input type="submit" value="Submit" />
            </form>
        </div>
    )
}

export default AddCat