import "./table.scss";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useEffect, useState } from "react";
import adminBackendHost from "../../Api";

const List = () => {

  const [payers, setPayers] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        console.log("INSIDE")
        adminBackendHost.get("/home/getDetails")
          .then(res => {
            setPayers(res.data.paymentData);
            console.log(res?.data)
          })
          .catch(err => {
            console.log(err)
          })
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date?.toISOString().split('T')[0];
  };

  return (
    <TableContainer component={Paper} className="table">
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell className="tableCell tableHead">Payer Name</TableCell>
            <TableCell className="tableCell tableHead">Payer Email</TableCell>
            <TableCell className="tableCell tableHead">Amount</TableCell>
            <TableCell className="tableCell tableHead">Plan</TableCell>
            <TableCell className="tableCell tableHead">Date</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {payers.map((payer) => (
            <TableRow key={payer?._id}>
              <TableCell className="tableCell">{payer?.payer?.userName}</TableCell>
              <TableCell className="tableCell">{payer?.payerEmail}</TableCell>
              <TableCell className="tableCell">{payer?.amount}</TableCell>
              <TableCell className="tableCell">{payer?.packageId?.packageName}</TableCell>
              <TableCell className="tableCell">{formatDate(payer?.createdAt)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default List;
