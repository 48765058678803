import "./widget.scss";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import { useContext, useEffect, useState } from "react";
import adminBackendHost from "../../Api";
import { AlertContext } from "../../context/alertContext";

const Widget = ({ type }) => {

  const [countUser, setCountUser] = useState(0);
  const [countBusiness, setCountBusiness] = useState(0);
  const [countCategory, setCountCategory] = useState(0);
  const [sum, setSum] = useState(0);
  const dispatchAlert = useContext(AlertContext)?.dispatch;

  useEffect(() => {
    const fetchDocumentCount = () => {
      try {
        adminBackendHost.get("/home/getDetails")
          .then((res => {
            console.log(res?.data)
            setCountUser(res?.data?.countUser)
            setCountBusiness(res?.data?.countBusiness)
            setCountCategory(res?.data?.countCategory)
            setSum(res?.data?.totalRevenue);
          }))
          .catch(err => {
            console.log(err)
            dispatchAlert({
              type: "SHOW_ALERT",
              payload: {
                type: "danger",
                message: err?.response?.data?.message,
              },
            });
          })
      } catch (error) {
        console.log(error)
        dispatchAlert({
          type: "SHOW_ALERT",
          payload: {
            type: "danger",
            message: error?.message,
          },
        });
      }
    };
    fetchDocumentCount();
  }, []);

  let data;
  let count;
  //temporary

  const diff = 20;

  switch (type) {
    case "user":
      data = {
        title: "USERS",
        isMoney: false,

        icon: (
          <PersonOutlinedIcon
            className="icon"
            style={{
              color: "crimson",
              backgroundColor: "rgba(255, 0, 0, 0.2)",
            }}
          />
        ),
      };
      count = countUser;
      break;
    case "order":
      data = {
        title: "BUSINESS LIST",
        isMoney: false,

        icon: (
          <ShoppingCartOutlinedIcon
            className="icon"
            style={{
              backgroundColor: "rgba(218, 165, 32, 0.2)",
              color: "goldenrod",
            }}
          />
        ),
      };
      count = countBusiness;
      break;
    case "earning":
      data = {
        title: "EARNINGS",
        isMoney: true,

        icon: (
          <MonetizationOnOutlinedIcon
            className="icon"
            style={{ backgroundColor: "rgba(0, 128, 0, 0.2)", color: "green" }}
          />
        ),
      };
      count = sum;
      break;
    case "balance":
      data = {
        title: "CATEGORIES",
        isMoney: false,

        icon: (
          <AccountBalanceWalletOutlinedIcon
            className="icon"
            style={{
              backgroundColor: "rgba(128, 0, 128, 0.2)",
              color: "purple",
            }}
          />
        ),
      };
      count = countCategory;
      break;
    default:
      break;
  }

  return (

    <div className="widget">
      <div className="left">
        <span className="title">{data.title}</span>
        <span className="counter">{data.isMoney && "$"} {count}</span>
      </div>
      <div className="right">

        {data.icon}
      </div>
    </div>
  );
};

export default Widget;
