import React, { useContext, useEffect, useState } from "react";
import "./Editviewdetails.scss";
import { useParams, useNavigate } from "react-router-dom";
import { State, City } from "country-state-city";
import adminBackendHost from "../../Api";
import { AlertContext } from "../../context/alertContext";

function Editviewdetails() {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatchAlert = useContext(AlertContext)?.dispatch;
  const [formData, setFormData] = useState({
    ownerFirstName: "",
    ownerLastName: "",
    businessName: "",
    businessEmail: "",
    businessNumber: "",
    category: "",
    subCategory: "",
    address: "",
    pin: "",
    city: "",
    state: "",
    country: "",
    language: "",
    businessLogo: "",
  });

  const [dynamicCategory, setDynamicCategory] = useState([]);
  const [dynamicSubcategoryMap, setDynamicSubcategoryMap] = useState({});
  const [states, setStates] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [logoPreview, setLogoPreview] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        adminBackendHost.get(`/business/getBusiness/${id}`)
          .then(res => {
            setFormData(res?.data?.business)
            setDynamicCategory(res?.data?.categoryNames)
            setDynamicSubcategoryMap(res?.data?.categorySubcategoryMap);
            setLogoPreview(res?.data?.business?.businessLogo || "");
          })
          .catch(err => {
            dispatchAlert({
              type: "SHOW_ALERT",
              payload: {
                type: "danger",
                message: err?.response?.data?.message,
              },
            });
          })
      } catch (error) {
        console.error("Error fetching data:", error);
        dispatchAlert({
          type: "SHOW_ALERT",
          payload: {
            type: "danger",
            message: error?.message,
          },
        });
      }
    };
    fetchData();
  }, [id]);

  useEffect(() => {
    const fetchStates = () => {
      if (formData?.country === "United States") {
        setStates(State.getStatesOfCountry("US").map(e => e.name));
      } else if (formData?.country === "Canada") {
        setStates(State.getStatesOfCountry("CA").map(e => e.name));
      } else {
        setStates([]);
      }
    };
    fetchStates();
  }, [formData?.country]);

  useEffect(() => {
    const fetchCities = () => {
      if (formData?.state) {
        const countryCode = formData?.country === "Canada" ? "CA" : "US";
        const stateCode = State.getStatesOfCountry(countryCode).find(s => s.name === formData?.state)?.isoCode;
        const cities = City.getCitiesOfState(countryCode, stateCode);
        setCityList(cities.map(c => c.name));
      }
    };
    fetchCities();
  }, [formData?.state, formData?.country]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      adminBackendHost.post(`/business/updateBusiness/${id}`, formData)
        .then(res => {
          setFormData(res?.data?.updatedBusiness);
          dispatchAlert({
            type: "SHOW_ALERT",
            payload: {
              type: "success",
              message: res?.data?.message,
            },
          });
          navigate("/businesses");
        })
        .catch(err => {
          dispatchAlert({
            type: "SHOW_ALERT",
            payload: {
              type: "danger",
              message: err?.response?.data?.message || "Error updating business",
            },
          });
        });
    } catch (error) {
      console.error("Error updating data:", error);
      dispatchAlert({
        type: "SHOW_ALERT",
        payload: {
          type: "danger",
          message: error?.message,
        },
      });
    }
  };


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleLogoChange = (e) => {
    const file = e.target.files[0];
    setFormData({ ...formData, businessLogo: file });
    setLogoPreview(URL.createObjectURL(file));
  };

  return (
    <div className="business-info">
      <div className="business-edit-from">
        <div className="business-info-title">
          <h1 className="info-title">Business-info</h1>
        </div>
        <hr style={{ marginBottom: "20px", width: "820px" }} />
        <div className="business-form">
          <form className="form-layout" onSubmit={handleSubmit}>
            <label className="lable-form">Business Owner</label>
            <div className="">
              <input
                type="text"
                name="ownerFirstName"
                placeholder="business owner first name"
                className="input-form"
                value={formData.ownerFirstName}
                onChange={handleInputChange}
              />
              <input
                type="text"
                name="ownerLastName"
                placeholder="business owner Last name"
                className="input-form"
                value={formData.ownerLastName}
                onChange={handleInputChange}
              />
            </div>
            <label className="lable-form">Business Name</label>
            <input
              type="text"
              name="businessName"
              placeholder="business name"
              value={formData.businessName}
              className="input-form-1"
              onChange={handleInputChange}
            />
            <label className="lable-form-1">
              <div className="lable-form-1-2">Business Email</div>
              <div className="lable-form-1-2">Business Phone No</div>
            </label>
            <div className="">
              <input
                type="text"
                name="businessEmail"
                placeholder="Business Email"
                value={formData.businessEmail}
                className="input-form"
                onChange={handleInputChange}
              />
              <input
                type="text"
                name="businessNumber"
                placeholder="Business Phone Number"
                value={formData.businessNumber}
                className="input-form"
                onChange={handleInputChange}
              />
            </div>
            <label className="lable-form-1">
              <div className="lable-form-1-2">Business category</div>
              <div className="lable-form-1-2">Business Sub-Category</div>
            </label>
            <div className="">
              <select
                className="input-form"
                aria-label=""
                name="category"
                value={formData.category}
                onChange={(e) => {
                  handleInputChange(e);
                  setFormData({ ...formData, subCategory: "" });
                }}
              >
                <option value="" disabled>
                  --Select Category--
                </option>
                {dynamicCategory.map((cate) => (
                  <option key={cate} value={cate}>
                    {cate}
                  </option>
                ))}
              </select>

              <select
                className="input-form"
                aria-label=""
                name="subCategory"
                value={formData.subCategory}
                onChange={handleInputChange}
              >
                <option value="" disabled>
                  --Select Sub Category--
                </option>
                {formData.category &&
                  dynamicSubcategoryMap[formData.category]?.map((subCate) => (
                    <option key={subCate} value={subCate}>
                      {subCate}
                    </option>
                  ))}
              </select>
            </div>
            <label className="lable-form">Business Address</label>
            <input
              type="text"
              name="address"
              placeholder="business Address"
              value={formData.address}
              className="input-form-1"
              onChange={handleInputChange}
            />
            <label className="lable-form-1">
              <div className="lable-form-1-2">Pincode</div>
              <div className="lable-form-1-2">City</div>
            </label>


            <div className="">
              <select
                className="input-form"
                aria-label=""
                name="country"
                value={formData.country}
                onChange={handleInputChange}
              >
                <option value="" disabled>
                  --Select Country--
                </option>
                <option value="United States">United States</option>
                <option value="Canada">Canada</option>
              </select>

              <select
                className="input-form"
                aria-label=""
                name="state"
                value={formData.state}
                onChange={handleInputChange}
              >
                <option value="" disabled>
                  --Select State--
                </option>
                {states.map((state, index) => (
                  <option key={index} value={state}>
                    {state}
                  </option>
                ))}
              </select>
            </div>
            <label className="lable-form-1">
              <div className="lable-form-1-2">Country</div>
              <div className="lable-form-1-2">State</div>
            </label>
            <div className="">
              <input
                type="text"
                name="pin"
                placeholder="Pincode"
                value={formData.pin}
                className="input-form"
                onChange={handleInputChange}
              />
              <select
                className="input-form"
                aria-label=""
                name="city"
                value={formData.city}
                onChange={handleInputChange}
              >
                <option value="" disabled>
                  --Select City--
                </option>
                {cityList.map((city, index) => (
                  <option key={index} value={city}>
                    {city}
                  </option>
                ))}
              </select>
            </div>
            <label className="lable-form-1">
              <div className="lable-form-1-2">Language</div>
              <div className="lable-form-1-2">Business Logo</div>
            </label>
            <div className="">
              <input
                type="text"
                name="language"
                placeholder="business Language"
                value={formData.language}
                className="input-form"
                onChange={handleInputChange}
              />
              <input
                type="file"
                className="input-form"
                accept="image/*"
                onChange={handleLogoChange}
              />
            </div>
            {logoPreview && (
              <div className="logo-preview">
                <img src={logoPreview} alt="Business Logo Preview" className="logo-image" />
              </div>
            )}

            <hr style={{ marginTop: "20px", width: "820px" }} />
            <button type="submit" className="btn-form">
              Save Changes
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Editviewdetails;
