import "./sidebar.scss";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import BusinessIcon from "@mui/icons-material/Business";
import CategoryIcon from "@mui/icons-material/Category";
import SubdirectoryArrowRightIcon from "@mui/icons-material/SubdirectoryArrowRight";
import HomeIcon from "@mui/icons-material/Home";
import WorkIcon from "@mui/icons-material/Work";
import CampaignIcon from "@mui/icons-material/Campaign";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import ContactsIcon from "@mui/icons-material/Contacts";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { Link, useNavigate } from "react-router-dom";
import { useContext, useState } from "react";
import { DarkModeContext } from "../../context/darkModeContext";
import { AlertContext } from "../../context/alertContext";

const Sidebar = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(true);
  const dispatchAlert = useContext(AlertContext)?.dispatch;
  const { dispatch } = useContext(DarkModeContext)
  const user = useContext(DarkModeContext)
  const navigate = useNavigate()
  const handleLogout = () => {
    dispatchAlert({
      type: "SHOW_ALERT",
      payload: {
        type: "success",
        message: "Logged out successfully."
      },
    });
    navigate("/")
    dispatch({ type: "RESETUSER" })
    setIsLoggedIn(false);
  };

  return (
    <div className="sidebar">
      <div className="top">
        <Link to="/home" style={{ textDecoration: "none" }}>
          <span className="logo">Admin</span>
        </Link>
      </div>
      <hr />
      <div className="center">
        <ul>
          <p className="title">MAIN</p>
          <Link to="/home" style={{ textDecoration: "none" }}>
            <li>
              <DashboardIcon className="icon" />
              <span>Dashboard</span>
            </li>
          </Link>
          <Link to="/users" style={{ textDecoration: "none" }}>
            <li>
              <PersonOutlineIcon className="icon" />
              <span>Users</span>
            </li>
          </Link>
          <Link to="/businesses" style={{ textDecoration: "none" }}>
            <li>
              <BusinessIcon className="icon" />
              <span>Business Properties</span>
            </li>
          </Link>
          <Link to="/home" style={{ textDecoration: "none" }}>
            <li>
              <HomeIcon className="icon" />
              <span>Rental Properties</span>
            </li>
          </Link>
          <Link to="/packages" style={{ textDecoration: "none" }}>
            <li>
              <LocalShippingIcon className="icon" />
              <span>Packages</span>
            </li>
          </Link>
          <Link to="/categories" style={{ textDecoration: "none" }}>
            <li>
              <CategoryIcon className="icon" />
              <span>Category</span>
            </li>
          </Link>
          <Link to="/subcategories" style={{ textDecoration: "none" }}>
            <li>
              <SubdirectoryArrowRightIcon className="icon" />
              <span>Sub-Category</span>
            </li>
          </Link>
          <Link to="/home" style={{ textDecoration: "none" }}>
            <li>
              <WorkIcon className="icon" />
              <span>Job</span>
            </li>
          </Link>
          <Link to="/home" style={{ textDecoration: "none" }}>
            <li>
              <CampaignIcon className="icon" />
              <span>Advertisement</span>
            </li>
          </Link>
          <Link to="/home" style={{ textDecoration: "none" }}>
            <li>
              <ContactsIcon className="icon" />
              <span>Candidates</span>
            </li>
          </Link>
          <Link to="/home" style={{ textDecoration: "none" }}>
            <li>
              <ManageAccountsIcon className="icon" />
              <span>Employers</span>
            </li>
          </Link>
          <p className="title">USER</p>

          {isLoggedIn && (
            <Link style={{ textDecoration: "none" }}>
              <li onClick={handleLogout}>
                <ExitToAppIcon className="icon" />
                <span>Logout</span>
              </li>
            </Link>
          )}
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
