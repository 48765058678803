import { createContext, useReducer } from "react";

const AlertReducer = (state, action) => {
    switch (action.type) {
        case "SHOW_ALERT":
            return { ...state, alert: action.payload };
        case "HIDE_ALERT":
            return { ...state, alert: null };
        default:
            return state;
    }
};

const INITIAL_STATE = {
    alert: null,
};

export const AlertContext = createContext(INITIAL_STATE);

export const AlertContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(AlertReducer, INITIAL_STATE);

    return (
        <AlertContext.Provider value={{ alert: state.alert, dispatch }}>
            {children}
        </AlertContext.Provider>
    );
};
