import React, { useContext, useEffect, useState } from "react";
import "./Add.scss";
import adminBackendHost from "../../Api";
import { DarkModeContext } from "../../context/darkModeContext";
import { AlertContext } from "../../context/alertContext";

function Addform() {
  const [name, setName] = useState("");
  const [fileImage, setFileImage] = useState(null);
  const { darkMode } = useContext(DarkModeContext)
  useEffect(() => { }, [darkMode])
  const dispatchAlert = useContext(AlertContext)?.dispatch;
  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("name", name);
    formData.append("file", fileImage);

    try {
      adminBackendHost.post("/category/addCategory", formData)
        .then(res => {
          console.log(res)
          dispatchAlert({
            type: "SHOW_ALERT",
            payload: {
              type: "success",
              message: res?.data?.message,
            },
          });
        })
        .catch(err => {
          console.log(err)
          dispatchAlert({
            type: "SHOW_ALERT",
            payload: {
              type: "danger",
              message: err?.response?.data?.message,
            },
          });
        })
    } catch (error) {
      dispatchAlert({
        type: "SHOW_ALERT",
        payload: {
          type: "danger",
          message: error?.message,
        },
      });
    }
  };

  return (
    <div className={darkMode ? "card-container-dark" : "card-container"}>
      <div className="form-1">
        <div className="form-add-category">
          <form onSubmit={handleSubmit} className="form-input">
            <br />

            <label className="input-label">Category Name:</label>
            <input
              type="text"
              value={name}
              placeholder="Enter your Category Name"
              onChange={(e) => setName(e.target.value)}
              className={darkMode ? "input-category-dark" : "input-category"}
            />

            <label htmlFor="logo-upload" className="custom-file-input">
              <span className="custom-file-input-label">Choose File</span>
              <input
                id="logo-upload"
                type="file"
                onChange={(e) => setFileImage(e.target.files[0])}
                className="input-category"
              />
            </label>

            <div style={{ textAlign: "center" }}>
              <button type="submit" className="button-form">
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Addform;