import React from 'react'
import CategoryTable from './CategoryTable'

function Category() {
  return (
    <div className="userlist">
      <div className="userlistContainer">
        <CategoryTable />
      </div>
    </div>
  )
}

export default Category