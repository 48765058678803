import React from 'react'
import PackageTable from './PackageTable'


const Package = () => {
  return (
    <div className="userlist">
      <div className="userlistContainer">
        <PackageTable />
      </div>
    </div>
  )
}

export default Package