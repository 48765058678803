import React from 'react'
import SubCategoryTable from './SubCategoryTable'

function Subcategory() {
  return (
    <div className="userlist">
      <div className="userlistContainer">
        <SubCategoryTable />
      </div>
    </div>
  )
}

export default Subcategory