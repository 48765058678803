import "./style/dark.scss";
import { useContext, useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import { DarkModeContext } from "./context/darkModeContext";
import AllRoutes from "./components/AllRoutes/AllRoutes";
import { AlertContextProvider } from "./context/alertContext";
import Alert from "./components/alert/Alert";

const App = () => {
    const { darkMode } = useContext(DarkModeContext);
    useEffect(() => {}, [darkMode]);
    return (
        <AlertContextProvider>
            <div className={darkMode ? "app dark" : "app"}>
                <BrowserRouter>
                    <Alert />
                    <AllRoutes />
                </BrowserRouter>
            </div>
        </AlertContextProvider>
    );
};

export default App;
