import React from "react";
import Addsubcategory from "./Addsubcategory";
import { useNavigate } from "react-router-dom";

function SubcategoryAdd() {
  const navigate = useNavigate()
  return (
    <div className="userlist">
      <div className="userlistContainer">
        <button className="backButton" onClick={() => navigate("/subcategories")}>
          Back
        </button>
        <h2 style={{ padding: "20px", color: " #7451f8" }}>Add Sub-Category</h2>
        <Addsubcategory />
      </div>
    </div>
  );
}

export default SubcategoryAdd;