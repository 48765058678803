import React from "react";
import PackageAdd from "./PackageAdd";
import "./PackageAdd.scss"

function Addpackage() {
  return (
    <div className="addPackageContainer">
      <PackageAdd />
    </div>
  );
}

export default Addpackage;