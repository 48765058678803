import React, { useContext, useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import adminBackendHost from "../../Api";
import { DarkModeContext } from "../../context/darkModeContext";
import "./PackageAdd.scss"
import { AlertContext } from "../../context/alertContext";
import { useNavigate } from "react-router-dom";
function PackageAdd() {
  const [packageName, setPackageName] = useState("");
  const [packagePrice, setPackagePrice] = useState("");
  const [packagePros, setPackagePros] = useState([""]);
  const [packageCons, setPackageCons] = useState([""]);
  const { darkMode } = useContext(DarkModeContext)
  const dispatchAlert = useContext(AlertContext)?.dispatch;
  const navigate = useNavigate();
  useEffect(() => { }, [darkMode])
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      adminBackendHost.post("/package/addPackage", {
        packageName,
        packagePrice,
        packagePros: packagePros.filter((pro) => pro.trim() !== ""),
        packageCons: packageCons.filter((cons) => cons.trim() !== ""),
      })
        .then(res => {
          console.log(res?.data)
          dispatchAlert({
            type: "SHOW_ALERT",
            payload: {
              type: "success",
              message: res?.data?.message,
            },
          });
          navigate('/packages')
        })
        .catch(err => {
          dispatchAlert({
            type: "SHOW_ALERT",
            payload: {
              type: "danger",
              message: err?.response?.data?.message,
            },
          });
        })
    } catch (error) {
      dispatchAlert({
        type: "SHOW_ALERT",
        payload: {
          type: "danger",
          message: error?.message,
        },
      });
    }
  };

  const handleProsChange = (index, value) => {
    const pros = [...packagePros];
    pros[index] = value;
    setPackagePros(pros);
  };

  const handleConsChange = (index, value) => {
    const cons = [...packageCons];
    cons[index] = value;
    setPackageCons(cons);
  };

  const addInputField = (type) => {
    if (type === "pros") {
      setPackagePros([...packagePros, ""]);
    } else if (type === "cons") {
      setPackageCons([...packageCons, ""]);
    }
  };

  const removeInputField = (index, type) => {
    if (type === "pros") {
      const pros = [...packagePros];
      pros.splice(index, 1);
      setPackagePros(pros);
    } else if (type === "cons") {
      const cons = [...packageCons];
      cons.splice(index, 1);
      setPackageCons(cons);
    }
  };

  return (
    <div className="packages addPackageForm">
      <div className="packages-tital">
        <h2>Add Package</h2>
      </div>
      <hr />
      <div className="packages-form">
        <form className="packages-form-1" action="" onSubmit={handleSubmit}>
          <label className="packages-label" htmlFor="">
            Packge Name:
          </label>
          <input
            className={darkMode ? "packages-input-dark" : "packages-input"}
            type="text"
            placeholder="Packge Name"
            value={packageName}
            onChange={(e) => setPackageName(e.target.value)}
            required
          />
          <label className="packages-label" htmlFor="">
            Package Price:
          </label>
          <input
            className={darkMode ? "packages-input-dark" : "packages-input"}
            type="number"
            placeholder="Packge Price(in $)"
            value={packagePrice}
            onChange={(e) => setPackagePrice(e.target.value)}
            required
          />

          <div style={{ "margin-top": " 10px" }}>
            <label className="packages-label">Package Services Available:</label>
            <div>
              {packagePros.map((pro, index) => (
                <div key={index}>
                  <input
                    className={darkMode ? "packages-input-1-dark" : "packages-input-1"}
                    type="text"
                    value={pro}
                    onChange={(e) => handleProsChange(index, e.target.value)}
                    required
                    placeholder="Add Packages Pros"
                  />
                  <button
                    className="packages-input-btn-1"
                    type="button"
                    onClick={() => removeInputField(index, "pros")}
                  >
                    Remove
                  </button>
                </div>
              ))}
              <button
                type="button"
                onClick={() => addInputField("pros")}
                className="packeges-input-btn-add"
              >
                <AddIcon />
              </button>
            </div>
            <div style={{ "margin-top": " 10px" }}>
              <label className="packages-label">Package Services Disabled:</label>
              {packageCons.map((cons, index) => (
                <div key={index}>
                  <input
                    style={darkMode ? {} : {}}
                    className="packages-input-1"
                    type="text"
                    value={cons}
                    onChange={(e) => handleConsChange(index, e.target.value)}
                    required
                  />
                  <button
                    type="button"
                    className="packages-input-btn-1"
                    onClick={() => removeInputField(index, "cons")}
                  >
                    Remove
                  </button>
                </div>
              ))}
              <button
                type="button"
                onClick={() => addInputField("cons")}
                className="packeges-input-btn-add"
              >
                <AddIcon />
              </button>
            </div>
          </div>
          <button type="submit" className="Packeges-input-btnsub">
            Submit
          </button>
        </form>
      </div>
    </div>
  );
}

export default PackageAdd;