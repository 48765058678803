
import React, { useContext, useEffect } from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom'
import Login from '../../pages/login/Login'
import Home from "../../pages/home/Home"
import Package from "../../components/packages/Package"
import Addpackage from "../../components/packages/AddPackage"
import Category from "../../components/category/Category"
import Add from "../../components/category/Add"
import ViewDetails from "../../pages/Viewdetails/ViewDetails"
import Subcategory from "../../components/subcategory/Subcategory"
import SubcategoryAdd from "../../components/subcategory/SubcategoryAdd"
import Userlist from "../../pages/userslist/Userlist"
import Businesslist from "../../pages/Businesslist/Businesslist"
import { DarkModeContext } from '../../context/darkModeContext'
import AddCat from '../AddCat'
import Sidebar from '../sidebar/Sidebar'
import Navbar from '../navbar/Navbar'
const AllRoutes = () => {
    const { user, darkMode } = useContext(DarkModeContext)
    console.log(darkMode)
    const navigate = useNavigate()
    useEffect(() => {
        if (!user?.email) {
            navigate("/")
        }
    }, [user])
    return (
        <>
            <div style={{ display: 'flex' }}>
                {
                    user?.email &&
                    <Sidebar />}
                <div >
                    {
                        user?.email && 
                        <Navbar />}
                    <div>
                        <Routes>
                            <Route path="/" element={<Login />} />
                            <Route path="/home" element={<Home />} />
                            <Route path="/packages" element={<Package />} />
                            <Route path="/packages/addPackage" element={<Addpackage />} />
                            <Route path="/categories" element={<Category />} />
                            <Route path="/category/add" element={<Add />} />
                            <Route path="/businesses/editBusiness/:id" element={<ViewDetails />} />
                            <Route path="/subcategories" element={<Subcategory />} />
                            <Route
                                path="/subcategory/add"
                                element={<SubcategoryAdd />}
                            />
                            <Route path="/users" element={<Userlist />} />
                            <Route path="/businesses" element={<Businesslist />} />
                            <Route path="/addCat" element={<AddCat />} />
                        </Routes>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AllRoutes