import Editviewdetails from "./Editviewdetails";

function ViewDetails() {
  return (
    <div className="businesslist">
      <div className="businesslistContainer">
        <Editviewdetails />
      </div>
    </div>
  );
}

export default ViewDetails;