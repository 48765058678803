import "./Businesslist.scss"
import Tables2 from "../../components/table/table2";

const Businesslist = () => {
  return (
    <div className="businesslist">
      <div className="businesslistContainer">
        <Tables2 />
      </div>
    </div>
  );
}

export default Businesslist;
