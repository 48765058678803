import React, { useContext, useEffect, useState } from 'react';
import { Button, Pagination, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, createTheme, ThemeProvider, styled, tableCellClasses } from '@mui/material';
import { Link } from 'react-router-dom';
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { DarkModeContext } from '../../context/darkModeContext';
import adminBackendHost from '../../Api';
import { AlertContext } from '../../context/alertContext';

const getTheme = (darkMode) => createTheme({
  palette: {
    mode: darkMode ? 'dark' : 'light',
    primary: {
      main: darkMode ? '#90caf9' : '#1976d2',
    },
    background: {
      paper: darkMode ? '#111' : '#fff',
      default: darkMode ? '#110e15' : '#fafafa',
    },
    text: {
      primary: darkMode ? '#fff' : '#000',
      secondary: darkMode ? '#aaa' : '#555',
    },
  },
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#7451f8",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    width: "400px",
    color: theme.palette.text.primary,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 1,
  },
}));

const PackageTable = () => {
  const { darkMode } = useContext(DarkModeContext);
  const [packages, setPackages] = useState([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage] = useState(10);
  const dispatchAlert = useContext(AlertContext)?.dispatch;
  useEffect(() => {
    fetchPackages();
  }, []);

  const fetchPackages = async () => {
    try {
      adminBackendHost.get("/package/getAllPackages")
        .then(res => { setPackages(res?.data?.packages) })
        .catch(err => {
          console.log(err, err?.response?.data?.message)
          dispatchAlert({
            type: "SHOW_ALERT",
            payload: {
              type: "danger",
              message: err?.response?.data?.message,
            },
          });

        })
    } catch (error) {
      dispatchAlert({
        type: "SHOW_ALERT",
        payload: {
          type: "danger",
          message: error?.message,
        },
      });
    }
  };

  const handleDelete = async (id) => {
    try {
      adminBackendHost.delete(`/package/deletePackage/${id}`)
        .then(res => {
          dispatchAlert({
            type: "SHOW_ALERT",
            payload: {
              type: "success",
              message: res?.data?.message,
            },
          });
          setPackages((prevPackages) =>
            prevPackages.filter((pkg) => pkg._id !== id)
          );
        })
        .catch(err => {
          console.log(err)
          dispatchAlert({
            type: "SHOW_ALERT",
            payload: {
              type: "danger",
              message: err?.response?.data?.message,
            },
          });
        })
    } catch (error) {
      dispatchAlert({
        type: "SHOW_ALERT",
        payload: {
          type: "danger",
          message: error?.message,
        },
      });
    }
  };

  const totalPages = Math.ceil(packages.length / rowsPerPage);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const indexOfLastItem = page * rowsPerPage;
  const indexOfFirstItem = indexOfLastItem - rowsPerPage;
  const currentItems = packages.slice(indexOfFirstItem, indexOfLastItem);

  const theme = getTheme(darkMode);

  return (
    <ThemeProvider theme={theme}>
      <div className="table-1">
        <div className="table-main">
          <div className="table-head">
            <h2 className="table-title">Package Details</h2>
            <div className="add_button">
              <Link to="/packages/addPackage">
                <Button variant="outlined">
                  <AddIcon style={{ fontSize: "20px", margin: "3px" }} />
                  add
                </Button>
              </Link>
            </div>
          </div>
          <div className="table-user">
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Sr.No</StyledTableCell>
                    <StyledTableCell>Package Name</StyledTableCell>
                    <StyledTableCell>Package Price</StyledTableCell>
                    <StyledTableCell>Services Available</StyledTableCell>
                    <StyledTableCell>Services Disabled</StyledTableCell>
                    <StyledTableCell>Edit</StyledTableCell>
                    <StyledTableCell>Delete</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {currentItems.map((pkg, index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell>
                        {index + 1 + indexOfFirstItem}
                      </StyledTableCell>
                      <StyledTableCell>{pkg.packageName}</StyledTableCell>
                      <StyledTableCell>{pkg.packagePrice}</StyledTableCell>
                      <StyledTableCell>{pkg.packagePros.map((pro, idx) => (
                        <span key={idx}>
                          {pro}
                          {idx !== pkg.packagePros.length - 1 ? ', ' : '.'}
                          <br />
                        </span>
                      ))}</StyledTableCell>
                      <StyledTableCell>{pkg.packageCons.map((con, idx) => (
                        <span key={idx}>
                          {con}
                          {idx !== pkg.packageCons.length - 1 ? ', ' : ''}
                          <br />
                        </span>
                      ))}</StyledTableCell>
                      <StyledTableCell>
                        <EditIcon />
                      </StyledTableCell>
                      <StyledTableCell>
                        <Link onClick={() => handleDelete(pkg._id)}>
                          <DeleteIcon />
                        </Link>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <div className="pagination">
              <Stack spacing={2}>
                <Pagination
                  count={totalPages}
                  page={page}
                  onChange={handleChangePage}
                  variant="outlined"
                  color="primary"
                  boundaryCount={2}
                />
              </Stack>
            </div>
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
}

export default PackageTable;
