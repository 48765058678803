import "./Userlist.scss";
import Table1 from "../../components/table/Table1";

function Businesslist() {
  return (
    <div className="userlist">
      <div className="userlistContainer">
        <Table1 />
      </div>
    </div>
  );
}

export default Businesslist;
