import Addform from "./Addform";
import { useNavigate } from "react-router-dom";

function Add() {
  const navigate = useNavigate()
  return (
    <div className="userlist">
      <div className="userlistContainer">
        <h2 style={{ padding: "20px", color: " #7451f8" }}>Add Category</h2>
        <button className="backButton" onClick={() => navigate("/categories")}>
          Back
        </button>
        <Addform />
      </div>
    </div>
  );
}

export default Add;