import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./Addsubcategory.scss"
import adminBackendHost from "../../Api";
import { AlertContext } from "../../context/alertContext";

function Addsubcategory() {
  const [name, setName] = useState("");
  const [subcategory, setSubcategory] = useState("");
  const [message, setMessage] = useState("");
  const [categories, setCategories] = useState([]);
  const navigate = useNavigate();
  const dispatchAlert = useContext(AlertContext)?.dispatch;

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        adminBackendHost.get("/subCategory/getAllSubCategories")
          .then(res => {
            setCategories(res.data.categoryNames);
          })
          .catch(err => {
            console.log(err)
            dispatchAlert({
              type: "SHOW_ALERT",
              payload: {
                type: "danger",
                message: err?.response?.data?.message,
              },
            });
          })
      } catch (error) {
        console.error("Error fetching categories:", error);
        dispatchAlert({
          type: "SHOW_ALERT",
          payload: {
            type: "danger",
            message: error?.message,
          },
        });
      }
    };

    fetchCategories();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      adminBackendHost.post("/subCategory/addSubCategory", { name, subcategory })
        .then(res => {
          console.log(res?.data);
          dispatchAlert({
            type: "SHOW_ALERT",
            payload: {
              type: "success",
              message: res?.data?.message,
            },
          });
          navigate("/subcategories")
        })
        .catch(err => dispatchAlert({
          type: "SHOW_ALERT",
          payload: {
            type: "danger",
            message: err?.response?.data?.message,
          },
        }))
    } catch (error) {
      dispatchAlert({
        type: "SHOW_ALERT",
        payload: {
          type: "danger",
          message: error?.message,
        },
      });
    }
  };
  return (
    <div className="card-container">
      <div className="form-1">
        <div className="form-add-category">
          <form onSubmit={handleSubmit} className="form-input">
            <br />

            <label>Category:</label>
            <select
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="input-category"
            >
              <option value="" selected disabled>Select a category</option>
              {categories.map((category) => (
                <option key={category} value={category}>
                  {category}
                </option>
              ))}
            </select>
            <label>Sub-Category:</label>
            <input
              type="text"
              value={subcategory}
              onChange={(e) => setSubcategory(e.target.value)}
              className="input-category"
            />
            <div style={{ textAlign: "center" }}>
              <button type="submit" className="button-form">
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Addsubcategory;