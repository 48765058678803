const DarkModeReducer = (state, action) => {
    switch (action.type) {
        case "LIGHT": {
            return { ...state, darkMode: false };
        }
        case "DARK": {
            return { ...state, darkMode: true };
        }
        case "TOGGLE": {
            console.log("INSIDE TOGGLE..", state);
            return { ...state, darkMode: !state.darkMode };
        }
        case "SETUSER": {
            return { ...state, user: action.payload };
        }
        case "RESETUSER": {
            return { ...state, user: {} };
        }
        default:
            return state;
    }
};

export default DarkModeReducer;
