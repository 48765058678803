import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import "./table2.scss";
import { Link } from "react-router-dom";
import { DarkModeContext } from "../../context/darkModeContext";
import adminBackendHost from "../../Api";
import { AlertContext } from "../../context/alertContext";

const getTheme = (darkMode) => createTheme({
  palette: {
    mode: darkMode ? 'dark' : 'light',
    primary: {
      main: darkMode ? '#90caf9' : '#1976d2',
    },
    background: {
      paper: darkMode ? '#111' : '#fff',
      default: darkMode ? '#110e15' : '#fafafa',
    },
    text: {
      primary: darkMode ? '#fff' : '#000',
      secondary: darkMode ? '#aaa' : '#555',
    },
  },
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#7451f8",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    width: "400px",
    color: theme.palette.text.primary,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 1,
  },
}));

export default function Tables2() {
  const { darkMode } = useContext(DarkModeContext);
  const [owners, setOwners] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [itemsPerPage] = useState(10);
  const dispatchAlert = useContext(AlertContext)?.dispatch;
  useEffect(() => {
    try {
      adminBackendHost
        .get("/business/getAllBusiness")
        .then((res) => setOwners(res?.data?.businesses))
        .catch((err) => {
          console.log(err)
          dispatchAlert({
            type: "SHOW_ALERT",
            payload: {
              type: "danger",
              message: err?.response?.data?.message,
            },
          });
        });
    } catch (error) {
      dispatchAlert({
        type: "SHOW_ALERT",
        payload: {
          type: "danger",
          message: error?.message,
        },
      });
    }
  }, []);

  const totalPages = Math.ceil(owners.length / itemsPerPage);

  const handlePageChange = (event, pageNumber) => {
    setActivePage(pageNumber);
  };

  const indexOfLastItem = activePage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = owners.slice(indexOfFirstItem, indexOfLastItem);

  const theme = getTheme(darkMode);

  return (
    <ThemeProvider theme={theme}>
      <div className="table-1">
        <div className="table-main">
          <div className=" table-head">
            <h2 className="table-title"> Business List</h2>
          </div>
          <div className="table-user">
            <TableContainer component={Paper} style={{ boxShadow: "0px 0px 2px 2px #ff" }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Sr.No</StyledTableCell>
                    <StyledTableCell>Business Logo</StyledTableCell>
                    <StyledTableCell>Business Name</StyledTableCell>
                    <StyledTableCell>Business Email</StyledTableCell>
                    <StyledTableCell>Owner Name</StyledTableCell>
                    <StyledTableCell>Contact Number</StyledTableCell>
                    <StyledTableCell>Business Category</StyledTableCell>
                    <StyledTableCell>City</StyledTableCell>
                    <StyledTableCell>View Details</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {currentItems.map((owner, index) => (
                    <StyledTableRow key={owner.id}>
                      <StyledTableCell>
                        {index + 1 + indexOfFirstItem}
                      </StyledTableCell>
                      <StyledTableCell>
                        <img
                          src={owner?.businessLogo ? owner?.businessLogo : ""}
                          alt={owner.businessName}
                          className="avatar"
                        />
                      </StyledTableCell>
                      <StyledTableCell>{owner.businessName}</StyledTableCell>
                      <StyledTableCell>{owner.email}</StyledTableCell>
                      <StyledTableCell>{owner.ownerFirstName} {owner.ownerLastName}</StyledTableCell>
                      <StyledTableCell>{owner.businessNumber}</StyledTableCell>
                      <StyledTableCell>{owner.category}</StyledTableCell>
                      <StyledTableCell>{owner.city}</StyledTableCell>
                      <StyledTableCell>
                        <span className="pagination-arrow">
                          <Link to={`/businesses/editBusiness/${owner._id}`}><VisibilityIcon /></Link>
                        </span>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <div className="pagination">
              <Stack spacing={2}>
                <Pagination
                  count={totalPages}
                  page={activePage}
                  onChange={handlePageChange}
                  variant="outlined"
                  color="primary"
                />
              </Stack>
            </div>
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
}
