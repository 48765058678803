import "./navbar.scss";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined"; // Import LightMode icon
import { DarkModeContext } from "../../context/darkModeContext";
import { useContext, useEffect } from "react";

const Navbar = () => {
  const { dispatch, darkMode } = useContext(DarkModeContext);
  const state = useContext(DarkModeContext);
  console.log(darkMode, state, dispatch);
  useEffect(() => { }, [dispatch]);

  return (
    <div className="navbar">
      <div className="wrapper">
        <div className="search">
          <input type="text" placeholder="Search..." />
          <SearchOutlinedIcon />
        </div>
        <div className="items">
          <div
            className="item"
            onClick={() => {
              dispatch({ type: "TOGGLE" });
            }}
          >
            {darkMode ? (
              <LightModeOutlinedIcon className="icon" />
            ) : (
              <DarkModeOutlinedIcon className="icon" />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
