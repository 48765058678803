import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./login.scss";
import { DarkModeContext } from "../../context/darkModeContext";
import adminBackendHost from "../../Api";
import { AlertContext } from "../../context/alertContext";

function Login() {
  const navigate = useNavigate();
  const { dispatch } = useContext(DarkModeContext)
  const dispatchAlert = useContext(AlertContext)?.dispatch;

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const submit = (e) => {
    e.preventDefault();
    try {
      adminBackendHost.post("/admin/login", { email, password })
        .then(res => {
          dispatchAlert({
            type: "SHOW_ALERT",
            payload: {
              type: "success",
              message: res?.data?.message,
            },
          });
          dispatch({ type: "SETUSER", payload: res?.data?.user })
          navigate("/home")
        })
        .catch(err => {
          dispatchAlert({
            type: "SHOW_ALERT",
            payload: {
              type: "danger",
              message: err?.response?.data?.message,
            },
          });
        })
    } catch (e) {
      console.log(e);
      dispatchAlert({
        type: "SHOW_ALERT",
        payload: {
          type: "danger",
          message: e?.message,
        },
      });
    }
  }

  return (
    <div className="container">
      <input type="checkbox" id="check" />
      <div className="login form">
        <header>Login</header>
        <form action="POST">
          <input
            type="email"
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            placeholder="Enter your email"
          />
          <input
            type="password"
            onChange={(e) => {
              setPassword(e.target.value);
            }}
            placeholder="Enter your password"
          />
          <input
            type="button"
            className="button"
            value="Login"
            onClick={submit}
          />
        </form>

      </div>
    </div>
  );
}

export default Login;
