import { useContext, useState, useEffect } from "react";
import './Alert.css';
import { AlertContext } from "../../context/alertContext";

const Alert = () => {
    const { alert, dispatch } = useContext(AlertContext);
    const [hide, setHide] = useState(false);

    useEffect(() => {
        if (alert) {
            setHide(false);
            const interval = setTimeout(() => {
                setHide(true);
                setTimeout(() => {
                    dispatch({ type: "HIDE_ALERT" });
                }, 600);  // Match the duration of the flipOut animation
            }, 3000);

            return () => clearTimeout(interval);
        }
    }, [alert, dispatch]);

    if (!alert) return null;

    return (
        <div className={`alert alert-${alert.type} ${hide ? "hide" : ""} alert-dismissible fade show`} role="alert">
            <p className="alertMessage">{alert.message}</p>
            <button type="button" className="closeButton" onClick={() => {
                setHide(true);
                setTimeout(() => {
                    dispatch({ type: "HIDE_ALERT" });
                }, 600);  // Match the duration of the flipOut animation
            }}>X</button>
        </div>
    );
};

export default Alert;
